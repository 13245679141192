import { TxModalTitle } from '../FlowCommons/TxModalTitle';
import { MenuList } from '../../../ui-kit/MenuList/MenuList';
import React, { useMemo } from 'react';
import { useConfiguredAutomationsQuery } from '../../../hooks/yldr/useConfiguredAutomationsQuery';
import { useRootStore } from '../../../store/root';
import { AUTOMATIONS_LIST } from './constants';
import { Chip } from '@mui/material';
import { EAutomationType } from './types';
import { ModalContextType, useModalContext } from '../../../hooks/useModal';

export const AutomationsModalContent = () => {
  const {
    openAutoCompoundFees,
    openAutoRebalance,
    openAutoExit,
    args,
  } = useModalContext() as ModalContextType<{
    marketId: number;
    positionAddress: string;
  }>;

  const marketData = useRootStore((store) => store.getMarketDataById(args.marketId));
  const { data: configuredAutomationsData } = useConfiguredAutomationsQuery({
    marketId: args.marketId,
    positions: [args.positionAddress]
  });

  const initializedAutomations: Record<EAutomationType, boolean | undefined> = useMemo(() => ({
    [EAutomationType.AutoCompounding]: configuredAutomationsData?.compounds[0].initialized,
    [EAutomationType.AutoRebalancing]: configuredAutomationsData?.rebalances[0].initialized,
    [EAutomationType.AutoExit]: configuredAutomationsData?.deleverages[0].initialized,
  }), [configuredAutomationsData]);

  const automationOptions = useMemo(() => {
    return AUTOMATIONS_LIST.map((item) => {
      const notAvailable = !marketData.addresses.leverageAutomationsAddress && !item.disabled;
      return {
        ...item,
        disabled: notAvailable ? true : item.disabled,
        endIcon: notAvailable
          ? <Chip color="error" label="Not available"/>
          : initializedAutomations[item.type]
            ? <Chip color="success" label="Enabled"/>
            : item.endIcon,
      }
    });
  }, [marketData.addresses.leverageAutomationsAddress, initializedAutomations]);

  const handleOnClick = (index: number) => {
    switch (AUTOMATIONS_LIST[index].type) {
      case EAutomationType.AutoCompounding:
        window.gtag('event', 'leverage_auto_compound_click');
        if (args.positionAddress) openAutoCompoundFees(args.positionAddress);
        return;
      case EAutomationType.AutoRebalancing:
        window.gtag('event', 'leverage_auto_rebalance_click');
        if (args.positionAddress) openAutoRebalance(args.positionAddress);
        return;
      case EAutomationType.AutoExit:
        window.gtag('event', 'leverage_auto_exit_click');
        if (args.positionAddress) openAutoExit(args.positionAddress);
        return;
    }
  };

  return (
    <>
      <TxModalTitle title="Select automation" sx={{ ml: 6 }} />
      <MenuList items={automationOptions} onSelect={handleOnClick} />
    </>
  );
};
