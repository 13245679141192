import React from 'react';
import { ModalType, useModalContext } from 'src/hooks/useModal';
import { ScrollModal } from '../../primitives/ScrollModal';
import { AutomationsModalContent } from './AutomationsModalContent';

export const AutomationsModal = () => {
  const { type, close } = useModalContext();

  return (
    <ScrollModal open={type === ModalType.Automations} setOpen={close}>
      <AutomationsModalContent />
    </ScrollModal>
  );
};
